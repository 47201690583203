import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/ResultView.vue')
    },
    {
      path: '/copyview',
      name: 'copyview',
      component: () => import('../views/AdvView.vue')
    },
    {
      path: '/Impressum',
      name: 'imprint',
      component: () => import('../views/ImprintView.vue')
    },
    {
      path: '/Datenschutz',
      name: 'privacy',
      component: () => import('../views/PrivacyView.vue')
    },
    {
      path: '/FAQ',
      name: 'faq',
      component: () => import('../views/FAQView.vue')
    },
    {
      path: '/Spenden',
      name: 'donate',
      component: () => import('../views/DonateView.vue')
    },
    {
      path: '/Hinzufuegen',
      name: 'addAdv',
      component: () => import('../views/addAdvView.vue')
    }
  ]
})

export default router
