<template>
    <div class="container-sm">
      <nav class="navbar navbar-expand-lg justify-content-center">
          <ul class="navbar-nav">
            <li class="nav-item" v-for="(link) in menuLinks" :key="link.id">
              <router-link :to="link.name">
                <a class="nav-link">{{ link.name }}</a>
              </router-link>
            </li>
          </ul>
      </nav>
      <div class="container text-center">v2.24</div>
    </div>
  </template>
  
  <script>
  export default {
    data: () => ({
      index: null,
      menuLinks: [
        {id: 1, name: 'Datenschutz'},
        {id: 2, name: 'Impressum'},
        {id: 3, name: 'FAQ'},
        {id: 4, name: 'Spenden'}
      ]
    })
  }
  </script>